import Router from './components/Router';
import './App.css';

function App() {
  return (
      <Router />
  );
}

export default App;
